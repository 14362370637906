import { ColumnDefinition } from "@tager/admin-ui";

import { ComplectationInterface } from "@/typings/model";

export const COLUMN_DEFS: Array<ColumnDefinition<ComplectationInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Бренд",
    field: "brand",
    style: { whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Модель",
    field: "model",
    style: { whiteSpace: "nowrap" },
  },
  {
    id: 4,
    name: "Поколение",
    field: "generation",
    style: { whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Имя",
    field: "name",
    style: { whiteSpace: "nowrap", fontWeight: "bold" },
  },
  {
    id: 6,
    name: "Коды",
    field: "codes",
  },
  {
    id: 7,
    name: "Наличие",
    field: "stock",
  },
];

<template>
  <Page
    title="Поколения"
    :header-buttons="[
      {
        text: 'Создать поколение',
        href: getGenerationCreateUrl(),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        totalCount,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #filters>
        <AdvancedSearch :tags="tags" @click:tag="tagRemovalHandler">
          <div class="filters">
            <FormFieldMultiSelect
              v-model:selected-options="brandFilter"
              :options="brandOptionsList"
              name="brandFilter"
              :searchable="true"
              label="Бренд"
              class="filter"
            />
            <FormFieldMultiSelect
              v-model:selected-options="modelFilter"
              :options="modelOptionsList"
              name="modelFilter"
              :searchable="true"
              label="Модель"
              class="filter"
            />
          </div>
          <div class="filters">
            <FormFieldMultiSelect
              v-model:selected-options="statusFilter"
              :options="statusOptionsList"
              name="status"
              label="Статус"
              class="filter"
            />
            <FormFieldMultiSelect
              v-model:selected-options="stockFilter"
              :options="stockOptionsList"
              name="stock"
              label="Наличие"
              class="filter"
            />
          </div>
        </AdvancedSearch>
      </template>

      <template #cell(id)="{ row }">
        <div :class="[{ 'no-active': !row.isPublic }]">
          {{ row.apiId }}
        </div>
      </template>
      <template #cell(brand)="{ row }">
        <div :class="[{ 'no-active': !row.isPublic }]">
          {{ row.brand }}
        </div>
      </template>
      <template #cell(model)="{ row }">
        <div :class="[{ 'no-active': !row.isPublic }]">
          {{ row.model }}
        </div>
      </template>

      <template #cell(stock)="{ row }">
        <div :class="['stock', { 'no-active': !row.isPublic }]">
          <div v-if="row.stockQuantity > 0" class="stock-section">
            <span class="stock-section-title">В наличии:</span>
            <span class="stock-title">{{ row.stockQuantity }} авто</span>

            <span class="stock-price"
              >от {{ formatPrice(row.stockMinPrice) }} BYN
              <template v-if="row.stockMaxPrice">
                <br />до {{ formatPrice(row.stockMaxPrice) }} BYN
              </template>
            </span>
          </div>

          <div
            v-if="
              row.stockNewAvailableQuantity &&
              row.stockNewAvailableQuantity !== row.stockQuantity
            "
            class="stock-section"
          >
            <span class="stock-section-title">Доступно:</span>
            <span class="stock-title"
              >{{ row.stockNewAvailableQuantity }} авто</span
            >

            <span class="stock-price"
              >от {{ formatPrice(row.stockNewAvailableMinPrice) }} BYN
              <template v-if="row.stockNewAvailableMaxPrice">
                <br />до {{ formatPrice(row.stockNewAvailableMaxPrice) }} BYN
              </template>
            </span>
          </div>

          <div v-if="row.stockNewPreOrderQuantity > 0" class="stock-section">
            <span class="stock-section-title">Под заказ:</span>
            <span class="stock-title"
              >{{ row.stockNewPreOrderQuantity }} авто</span
            >
            <span class="stock-price"
              >от {{ formatPrice(row.stockNewPreOrderMinPrice) }} BYN
              <template v-if="row.stockNewPreOrderMaxPrice">
                <br />до {{ formatPrice(row.stockNewPreOrderMaxPrice) }} BYN
              </template>
            </span>
          </div>

          <div v-if="row.stockQuantity > 0" class="stock-link">
            <a :href="row.stockUrl" target="_blank">Склад</a>
          </div>
        </div>
      </template>

      <template #cell(website)="{ row }">
        <div :class="[{ 'no-active': !row.isPublic }]">
          <div v-if="!row.isPublic">Не отображается</div>
          <div v-else>
            <div v-if="!row.url">Активно</div>
            <div v-else>
              <a :href="row.url" target="_blank">{{ row.url }}</a>
            </div>
          </div>
        </div>
      </template>

      <template #cell(relations)="{ row }">
        <div class="relations">
          <router-link
            :to="{
              path: getComplectationListUrl(),
              query: { 'filter[generation]': row.id },
            }"
          >
            <CountButton
              variant="outline-secondary"
              :count="row.complectationsCount"
              class="margin-right"
            >
              Комплектации
            </CountButton>
          </router-link>
        </div>
      </template>

      <template #cell(actions)="{ row, totalRowIndex, totalCount }">
        <BaseButton
          v-if="canMove"
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === totalCount - 1"
          @click="handleResourceMove(row.id, 'down')"
        >
          <SouthIcon />
        </BaseButton>
        <BaseButton
          v-if="canMove"
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up')"
        >
          <NorthIcon />
        </BaseButton>
        <BaseButton
          v-if="canMove"
          variant="icon"
          title="Переместить в самый верх"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up-top')"
        >
          <MoveTopIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy(row.id)"
          :href="getGenerationFormUrl({ generationId: row.id })"
        >
          <EditIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id) || !row.canDelete"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { isEqual, pick } from "lodash";
import { useRoute, useRouter } from "vue-router";

import {
  useDataTable,
  DataTable,
  AdvancedSearch,
  FormFieldMultiSelect,
  SouthIcon,
  NorthIcon,
  EditIcon,
  BaseButton,
  DeleteIcon,
  CountButton,
  MoveTopIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import {
  deleteGeneration,
  getGenerationList,
  moveGeneration,
} from "@/services/requests";
import { GenerationInterface } from "@/typings/model";
import {
  getGenerationFormUrl,
  getComplectationListUrl,
  getGenerationCreateUrl,
} from "@/utils/paths";
import { useFetchBrands } from "@/hooks/useFetchBrands";
import { useFetchModels } from "@/hooks/useFetchModels";
import { COLUMN_DEFS } from "@/views/GenerationList/GenerationList.helpers";
import { useAdvancedSearch } from "@/views/GenerationList/hooks";

export default defineComponent({
  name: "GenerationList",
  components: {
    Page,
    DataTable,
    AdvancedSearch,
    FormFieldMultiSelect,
    SouthIcon,
    NorthIcon,
    EditIcon,
    DeleteIcon,
    BaseButton,
    CountButton,
    MoveTopIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { data: brandList, loading: isBrandsListLoading } = useFetchBrands();

    const { data: modelList, loading: isModelsListLoading } = useFetchModels();

    const {
      brandFilter,
      brandOptionsList,
      modelFilter,
      modelOptionsList,
      statusFilter,
      statusOptionsList,
      stockFilter,
      stockOptionsList,
      filterParams,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({
      brandList,
      modelList,
    });

    const formatPrice = (num: number) =>
      String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");

    const {
      fetchEntityList: fetchGenerationList,
      isLoading: isGenerationListDataLoading,
      rowData: generationListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      totalCount,
    } = useDataTable<GenerationInterface>({
      fetchEntityList: (params) =>
        getGenerationList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список поколений",
    });

    watch(filterParams, () => {
      if (isBrandsListLoading.value || isModelsListLoading.value) {
        return;
      }

      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchGenerationList();
      }
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveGeneration,
      resourceName: "Generations",
      onSuccess: fetchGenerationList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteGeneration,
      resourceName: "Category",
      onSuccess: fetchGenerationList,
    });

    const isRowDataLoading = computed<boolean>(
      () =>
        isGenerationListDataLoading.value ||
        isBrandsListLoading.value ||
        isModelsListLoading.value
    );

    const canMove = computed<boolean>(
      () => Object.values(filterParams.value).length === 0
    );

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isRowDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: generationListData,
      isRowDataLoading: isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      totalCount,

      isBusy,
      getGenerationCreateUrl,
      getGenerationFormUrl,

      formatPrice,

      handleResourceMove,
      handleResourceDelete,

      brandFilter,
      brandOptionsList,
      modelFilter,
      modelOptionsList,
      statusFilter,
      statusOptionsList,
      stockFilter,
      stockOptionsList,
      tagRemovalHandler,
      tags,
      canMove,

      getComplectationListUrl,
    };
  },
});
</script>

<style scoped lang="scss">
a {
  color: #007bff;
}

.filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.no-active {
  color: rgba(10, 10, 10, 0.35);
}

.stock {
  white-space: nowrap;

  .stock-section:not(:first-child) {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }

  .stock-title {
    display: block;
  }

  .stock-section-title {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .stock-price {
    display: block;
    margin-top: 10px;
  }

  .stock-link {
    margin-top: 15px;

    a {
      color: #007bff;
    }
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-67e9fcfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row-cols-2" }
const _hoisted_3 = {
  key: 1,
  class: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading,
    tabs: _ctx.isContentLoading ? [] : _ctx.tabList
  }, {
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'general' ? 'block' : 'none' })
            }, [
              (_ctx.isCustom)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_FormFieldSelect, {
                        value: _ctx.values.brand,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.brand) = $event)),
                        name: "brand",
                        error: _ctx.errors.brand,
                        label: "Бренд",
                        options: _ctx.brandOptions
                      }, null, 8, ["value", "error", "options"]),
                      _createVNode(_component_FormFieldSelect, {
                        value: _ctx.values.model,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.model) = $event)),
                        name: "model",
                        error: _ctx.errors.model,
                        label: "Модель",
                        options: _ctx.modelOptions,
                        disabled: !_ctx.values.brand?.value
                      }, null, 8, ["value", "error", "options", "disabled"])
                    ]),
                    _createVNode(_component_FormField, {
                      value: _ctx.values.name,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.name) = $event)),
                      name: "name",
                      error: _ctx.errors.name,
                      label: "Название"
                    }, null, 8, ["value", "error"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_FieldValue, {
                      label: "API ID",
                      type: "text",
                      value: String(_ctx.model?.apiId) ?? ''
                    }, null, 8, ["value"]),
                    _createVNode(_component_FieldValue, {
                      label: "Бренд",
                      type: "text",
                      value: _ctx.model?.brand?.name ?? ''
                    }, null, 8, ["value"]),
                    _createVNode(_component_FieldValue, {
                      label: "Модель",
                      type: "text",
                      value: _ctx.model?.model?.name ?? ''
                    }, null, 8, ["value"]),
                    _createVNode(_component_FieldValue, {
                      label: "Поколение",
                      type: "text",
                      value: _ctx.model?.name ?? ''
                    }, null, 8, ["value"])
                  ])),
              _createVNode(_component_FormField, {
                value: _ctx.values.reviewUrl,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.reviewUrl) = $event)),
                name: "reviewUrl",
                error: _ctx.errors.reviewUrl,
                label: "Ссылка на обзор"
              }, null, 8, ["value", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'params' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.electric,
                "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.electric) = $event)),
                name: "electric",
                error: _ctx.errors.electric,
                label: "Электро"
              }, null, 8, ["checked", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'options' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.vinNewHidden,
                "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.vinNewHidden) = $event)),
                name: "vinNewHidden",
                label: "VIN скрыт на новых авто",
                error: _ctx.errors.vinNewHidden
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.pricesHidden,
                "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.pricesHidden) = $event)),
                name: "pricesHidden",
                label: "Не отображать цены",
                error: _ctx.errors.pricesHidden
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.deliveryDisabled,
                "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.deliveryDisabled) = $event)),
                name: "deliveryDisabled",
                label: "Поставки приостановлены",
                error: _ctx.errors.deliveryDisabled
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.saleDisabled,
                "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.saleDisabled) = $event)),
                name: "saleDisabled",
                label: "Недоступен к заказу",
                error: _ctx.errors.saleDisabled
              }, null, 8, ["checked", "error"]),
              (_ctx.values.saleDisabled)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 0,
                    value: _ctx.values.saleDisabledText,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.saleDisabledText) = $event)),
                    name: "saleDisabledText",
                    error: _ctx.errors.saleDisabledText,
                    label: "Пояснение (Недоступен к заказу)"
                  }, null, 8, ["value", "error"]))
                : _createCommentVNode("", true)
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'catalog' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.isPublic,
                "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.isPublic) = $event)),
                name: "isPublic",
                label: "Показывать в каталоге",
                error: _ctx.errors.isPublic
              }, null, 8, ["checked", "error"]),
              (_ctx.values.isPublic)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 0,
                    value: _ctx.values.badge,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.badge) = $event)),
                    name: "badge",
                    error: _ctx.errors.badge,
                    label: "Бейдж"
                  }, null, 8, ["value", "error"]))
                : _createCommentVNode("", true),
              (_ctx.values.isPublic)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 1,
                    value: _ctx.values.badgeHint,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.badgeHint) = $event)),
                    name: "badgeHint",
                    error: _ctx.errors.badgeHint,
                    label: "Бейдж - Подсказка"
                  }, null, 8, ["value", "error"]))
                : _createCommentVNode("", true),
              (_ctx.values.isPublic)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 2,
                    value: _ctx.values.publicUrl,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.publicUrl) = $event)),
                    name: "publicUrl",
                    error: _ctx.errors.publicUrl,
                    label: "Web URL"
                  }, null, 8, ["value", "error"]))
                : _createCommentVNode("", true),
              (_ctx.imagesField)
                ? (_openBlock(), _createBlock(_component_DynamicField, {
                    key: 3,
                    field: _ctx.imagesField
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'gallery' ? 'block' : 'none' })
            }, [
              (_ctx.galleryField)
                ? (_openBlock(), _createBlock(_component_DynamicField, {
                    key: 0,
                    field: _ctx.galleryField
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}
import { Nullable, request, ResponseBody } from "@tager/admin-services";

import {
  BodyInterface,
  BrandFullInterface,
  BrandInterface,
  ColorInterface,
  ComplectationInterface,
  FullGenerationInterface,
  GenerationInterface,
  KufarBrand,
  ModelFullInterface,
  ModelInterface,
} from "@/typings/model";

export interface CountData {
  count: number;
}

export function getBodiesList(): Promise<ResponseBody<Array<BodyInterface>>> {
  return request.get({ path: "/admin/bodies" });
}

export function getColorList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ColorInterface>>> {
  return request.get({ path: "/admin/colors", params });
}

export function getBrandCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/brands/count" });
}

export function getKufarData(): Promise<ResponseBody<Array<KufarBrand>>> {
  return request.get({ path: "/admin/kufar/catalog" });
}

export function getBrandList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<BrandInterface>>> {
  return request.get({ path: "/admin/brands", params });
}

export function getBrand(
  brandId: string | number
): Promise<ResponseBody<BrandFullInterface>> {
  return request.get({ path: `/admin/brands/${brandId}` });
}

export interface BrandUpdatePayload {
  displayName: string | null;
  showInCatalog: boolean;
  priority: number;
  kufarId: Nullable<string>;
  kufarName: Nullable<string>;

  logo: string | null;
  cover: string | null;
  title: string | null;
  textShort: string | null;
  textFull: string | null;
  warrantyText: string | null;
}

export function updateBrand(
  brandId: number | string,
  payload: BrandUpdatePayload
): Promise<ResponseBody<BrandFullInterface>> {
  return request.put({
    path: `/admin/brands/${brandId}`,
    body: payload,
  });
}

export function getModelCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/models/count" });
}

export function getModelList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ModelInterface>>> {
  return request.get({ path: "/admin/models", params });
}

export function getModel(
  modelId: string | number
): Promise<ResponseBody<ModelFullInterface>> {
  return request.get({ path: `/admin/models/${modelId}` });
}

export interface ModelUpdatePayload {
  image: string | null;
  description: string | null;
  shortDescription: string | null;
}

export function updateModel(
  modelId: number | string,
  payload: ModelUpdatePayload
): Promise<ResponseBody<BrandFullInterface>> {
  return request.put({
    path: `/admin/models/${modelId}`,
    body: payload,
  });
}

export function getComplectationCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/complectations/count" });
}

export function getComplectationList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ComplectationInterface>>> {
  return request.get({ path: "/admin/complectations", params });
}

export function getGenerationCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/generations/count" });
}

export function getGenerationList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<GenerationInterface>>> {
  return request.get({ path: "/admin/generations", params });
}

export function getGeneration(
  generationId: string | number
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.get({ path: `/admin/generations/${generationId}` });
}

export interface GenerationCreateOrUpdatePayload {
  name: Nullable<string>;
  model: Nullable<number>;
  badge: Nullable<string>;
  badgeHint: Nullable<string>;
  kufarId: Nullable<string>;
  kufarName: Nullable<string>;
  images: Array<{
    color: Nullable<number>;
    images: Array<string>;
  }>;
  galleryItems: Array<{
    file: Nullable<string>;
    videoUrl: Nullable<string>;
    category: Nullable<string>;
  }>;
  electric: boolean;
  reviewUrl: Nullable<string>;
  isPublic: boolean;
  publicUrl: Nullable<string>;
  deliveryDisabled: boolean;
  pricesHidden: boolean;
  vinNewHidden: boolean;
  saleDisabled: boolean;
  saleDisabledText: Nullable<string>;
}

export function updateGeneration(
  generationId: number | string,
  payload: GenerationCreateOrUpdatePayload
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.put({
    path: `/admin/generations/${generationId}`,
    body: payload,
  });
}

export function moveGeneration(
  id: number | string,
  direction: "up" | "down" | "up-top"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/generations/${id}/move/${direction}`,
  });
}

export function deleteGeneration(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/generations/${id}`,
  });
}

export function createGeneration(
  body: GenerationCreateOrUpdatePayload
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.post({
    path: `/admin/generations`,
    body,
  });
}

import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";

import Home from "@/views/Home.vue";
import { getLinks } from "@/constants/links";
import { ROUTE_PATHS } from "@/constants/paths";
import BrandList from "@/views/BrandList";
import BrandForm from "@/views/BrandForm";
import ModelList from "@/views/ModelList";
import ModelForm from "@/views/ModelForm";
import GenerationList from "@/views/GenerationList";
import GenerationForm from "@/views/GenerationForm";
import ComplectationList from "@/views/ComplectationList";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, { t }) => [{ url: "/", text: t("admin:home") }],
  },
};

export const BRAND_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRAND_LIST,
  component: BrandList,
  name: "Бренды",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).BRAND_LIST,
    ],
  },
};

export const BRAND_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRAND_FORM,
  component: BrandForm,
  name: "Форма Бренда",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).BRAND_LIST,
      {
        url: route.path,
        text: "Форма бренда",
      },
    ],
  },
};

export const MODEL_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.MODEL_LIST,
  component: ModelList,
  name: "Модели",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).MODEL_LIST,
    ],
  },
};

export const MODEL_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.MODEL_FORM,
  component: ModelForm,
  name: "Форма модели",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).MODEL_LIST,
      {
        url: route.path,
        text: "Форма модели",
      },
    ],
  },
};

export const GENERATION_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GENERATION_LIST,
  component: GenerationList,
  name: "Поколения",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).GENERATION_LIST,
    ],
  },
};

export const GENERATION_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GENERATION_FORM,
  component: GenerationForm,
  name: "Форма поколения",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).GENERATION_LIST,
      {
        url: route.path,
        text: "Форма поколения",
      },
    ],
  },
};

export const COMPLECTATION_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.COMPLECTATION_LIST,
  component: ComplectationList,
  name: "Комплектации",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).COMPLECTATION_LIST,
    ],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,
    BRAND_LIST_ROUTE,
    BRAND_FORM_ROUTE,
    MODEL_LIST_ROUTE,
    MODEL_FORM_ROUTE,
    GENERATION_LIST_ROUTE,
    GENERATION_FORM_ROUTE,
    COMPLECTATION_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,

    PAGE_COMMANDS_ROUTE,
    PAGE_CRON_LOGS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_CRON_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,
  ],
});

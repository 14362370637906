import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getBrandListUrl() {
  return ROUTE_PATHS.BRAND_LIST;
}

export function getBrandFormUrl(params: { brandId: string | number }) {
  return compile(ROUTE_PATHS.BRAND_FORM)(params);
}

export function getModelListUrl() {
  return ROUTE_PATHS.MODEL_LIST;
}

export function getModelFormUrl(params: { modelId: string | number }) {
  return compile(ROUTE_PATHS.MODEL_FORM)(params);
}

export function getGenerationListUrl() {
  return ROUTE_PATHS.GENERATION_LIST;
}

export function getComplectationListUrl() {
  return ROUTE_PATHS.COMPLECTATION_LIST;
}

export function getGenerationFormUrl(params: {
  generationId: string | number;
}) {
  return compile(ROUTE_PATHS.GENERATION_FORM)(params);
}

export function getGenerationCreateUrl() {
  return compile(ROUTE_PATHS.GENERATION_FORM)({
    generationId: "create",
  });
}

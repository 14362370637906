<template>
  <Page title="Комплектации">
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(codes)="{ row }">
        <div class="codes">
          <span
            v-for="item in row.codes ? row.codes.split(',') : []"
            :key="item"
          >
            {{ item }}
          </span>
        </div>
      </template>
      <template #cell(stock)="{ row }">
        <div :class="['stock']">
          <div v-if="row.stockQuantity > 0">
            <span class="stock-title">{{ row.stockQuantity }} авто</span>
            <span class="stock-price"
              >от {{ formatPrice(row.stockMinPrice) }} BYN<br />до
              {{ formatPrice(row.stockMaxPrice) }} BYN</span
            >
          </div>
          <span v-else> - </span>
        </div>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { isEqual, pick } from "lodash";
import { useRoute, useRouter } from "vue-router";

import { useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getComplectationList } from "@/services/requests";
import { ComplectationInterface } from "@/typings/model";
import { getGenerationFormUrl } from "@/utils/paths";
import { useFetchBrands } from "@/hooks/useFetchBrands";
import { useFetchModels } from "@/hooks/useFetchModels";
import { useAdvancedSearch } from "@/views/ComplectationList/hooks";

import { COLUMN_DEFS } from "./ComplectationList.helpers";

export default defineComponent({
  name: "ComplectationList",
  components: { Page, DataTable },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { data: brandList, loading: isBrandsListLoading } = useFetchBrands();

    const { data: modelList, loading: isModelsListLoading } = useFetchModels();

    const {
      brandFilter,
      brandOptionsList,
      modelFilter,
      modelOptionsList,
      statusFilter,
      statusOptionsList,
      stockFilter,
      stockOptionsList,
      filterParams,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({
      brandList,
      modelList,
    });

    const formatPrice = (num: number) =>
      String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");

    const {
      fetchEntityList: fetchGenerationList,
      isLoading: isGenerationListDataLoading,
      rowData: generationListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ComplectationInterface>({
      fetchEntityList: (params) =>
        getComplectationList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список поколений",
    });

    watch(filterParams, () => {
      if (isBrandsListLoading.value || isModelsListLoading.value) {
        return;
      }

      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchGenerationList();
      }
    });

    const isRowDataLoading = computed<boolean>(
      () =>
        isGenerationListDataLoading.value ||
        isBrandsListLoading.value ||
        isModelsListLoading.value
    );

    function isBusy(): boolean {
      return isRowDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: generationListData,
      isRowDataLoading: isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getGenerationFormUrl,

      formatPrice,

      brandFilter,
      brandOptionsList,
      modelFilter,
      modelOptionsList,
      statusFilter,
      statusOptionsList,
      stockFilter,
      stockOptionsList,
      tagRemovalHandler,
      tags,
    };
  },
});
</script>

<style scoped lang="scss">
a {
  color: #007bff;
}

.filters {
  display: flex;
  margin: 0 -10px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  .filter {
    padding: 10px 10px 0;
    width: 50%;
    margin: 0;
  }
}

.codes {
  display: flex;
  flex-direction: column;
}

.no-active {
  color: rgba(10, 10, 10, 0.35);
}

.stock {
  white-space: nowrap;

  .stock-title {
    display: block;
  }

  .stock-price {
    display: block;
    margin-top: 10px;
  }

  .stock-link {
    margin-top: 15px;

    a {
      color: #007bff;
    }
  }
}
</style>
